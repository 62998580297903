<template>
    <div class="row">
        <div class="col">
            <div class="avatar">
                <img src="@/assets/Kaufmann_6418.jpg" alt="">
            </div>
        </div>

        <div class="col">
            <div>
                <span>Erik Kaufmann</span>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div>
                <!-- <span>geboren am 24.06.1994</span> -->
            </div>
        </div>
        <div class="col">
            <div>
                <!-- <span>Softwareentwickler</span> -->
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AboutMe'
}
</script>

<style>
.row
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 10px;
}

.col
{
    display: flex;
    margin: 20px;
}
.avatar{
    width: 180px;
    height: 180px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #69AAA3;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.avatar img {
    width: 100%;  
    height: 100%;  
    object-fit: cover;  
    object-position: center -15px; /* Bild um 5% nach unten verschieben */
}

@media screen and (max-width: 768px) {

    .row{
        display: block;
    }
    
}

</style>