

import { 
    createRouter,
     createWebHistory 
    } from 'vue-router';
import Lebenslauf from '@/components/LebensLauf.vue';
import LandingPage from '@/components/LandingPage.vue';
import Projects from '@/components/MainProjects.vue';

const routes = [
  {
    path: '/lebenslauf',
    name: 'Lebenslauf',
    component: Lebenslauf
  },
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/projekte',
    name: 'Projekte',
    component: Projects
  },
  // ... andere Routen
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });
  

export default router;
