<template>
    <AboutMe />
    <TimeLine :entries="sortedEntries" />
</template>

<script>
import TimeLine from "@/components/TimeLine.vue";
import AboutMe from "./AboutMe.vue";

export default {
    components: {
    TimeLine,
    AboutMe
},
    computed: {
        sortedEntries() {
            // return [...this.experienceEntries].sort((a, b) => b.bis.year - a.bis.year || a.von.year - b.von.year);
            return [...this.experienceEntries].sort((a, b) => b.bis.fulldate - a.bis.fulldate);
        },
    },
    data() {
        return {
            experienceEntries: [
                {
                    id: 1,
                    subject: 'Berufserfahrung',
                    title: 'Softwareentwicklung C#',
                    company: 'Creative Software GmbH',
                    city: 'Weilheim Teck',
                    description: 'Softwareentwicklung mit OOP in C# & Java, Anwendung von SQL,WinForms, WPF, Softwaretests und Dokumentation',
                    von: {
                        year: 2015,
                        month: 'Juli'
                    },
                    bis: {
                        year: 2016,
                        month: 'Juli',
                        fulldate: '2016-07-31'
                    }
                },
                {
                    id: 2,
                    subject: 'Berufserfahrung',
                    title: 'Softwareentwicklung - Fullstack PHP/Angular',
                    company: 'eder GmbH',
                    city: 'Ostfildern',
                    description: 'Kundenwebseiten-Optimierung, Technische Anleitung von Azubis & Informatik-Studenten, Erweiterung von Shopsystemen, Multimedia-Anwendung unter Einsatz von 3D-Inhalten und interaktivem Gizmo. Durchführung von Projekten mit HTML, CSS, PHP, Javascript, TypeScript, Angular, Linux, SQL.',
                    von: {
                        year: 2017,
                        month: 'Januar'
                    },
                    bis: {
                        year: 2018,
                        month: 'Dezember',
                        fulldate: '2018-12-31'
                    }
                },
                {
                    id: 3,
                    subject: 'Berufserfahrung',
                    title: 'Softwareentwicklung C#',
                    company: 'Softwarehelden GmbH',
                    city: 'Stuttgart',
                    description: 'Durchführung von agilen Softwareprojekten; C#, TypeScript, CSS, HTML; MS SQL, Oracle SQL, Git, Visual Studio, LINQPad, SQL Server Management Studio.',
                    von: {
                        year: 2018,
                        month: 'Januar'
                    },
                    bis: {
                        year: 2020,
                        month: 'Dezember',
                        fulldate: '2020-12-31'
                    }
                },
                {
                    id: 4,
                    subject: 'Berufserfahrung',
                    title: 'Minijob - Fullstack C#/.NET, Vue.js',
                    company: 'DIRS21 by TourOnline AG',
                    city: 'Wernau',
                    description: 'Minijob Fullstack C#/.NET, JavaScript, HTML, CSS, SQL, RESTful API, Agile & Scrum.',
                    von: {
                        year: 2020,
                        month: 'November'
                    },
                    bis: {
                        year: 2021,
                        month: 'Oktober',
                        fulldate: '2021-10-31'
                    }
                },
                {
                    id: 5,
                    subject: 'Berufserfahrung',
                    title: 'Werkstudent - Fullstack JavaScript, PHP',
                    company: 'AceArt GmbH',
                    city: 'Stuttgart',
                    description: 'Softwareentwicklung mit JavaScript, HTML, PHP und CSS.',
                    von: {
                        year: 2023,
                        month: 'Januar'
                    },
                    bis: {
                        year: 2023,
                        month: 'Dezember',
                        fulldate: '2023-12-31'
                    }
                },
                {
                    id: 6,
                    subject: 'Studium',
                    title: 'Softwaretechnik Studium',
                    company: 'Hochschule Esslingen',
                    city: 'Esslingen',
                    description: '',
                    von: {
                        year: 2023,
                        month: 'Februar'
                    },
                    bis: {
                        year: 2023,
                        month: 'September',
                        fulldate: '2023-09-30'
                    }
                },
                {
                    id: 7,
                    subject: 'Studium',
                    title: 'Duales Informatik Studium',
                    company: 'DIRS21 by TourOnline AG',
                    city: 'Stuttgart',
                    description: '',
                    von: {
                        year: 2021,
                        month: 'Oktober'
                    },
                    bis: {
                        year: 2023,
                        month: 'März',
                        fulldate: '2023-03-01'
                    }
                },
                {
                    id: 8,
                    subject: 'Schulbildung',
                    title: 'Fachhochschule - Technik',
                    company: 'Friedrich-Ebert-Schule',
                    city: 'Esslingen',
                    description: '',
                    von: {
                        year: 2020,
                        month: 'September'
                    },
                    bis: {
                        year: 2021,
                        month: 'Juli',
                        fulldate: '2021-07-31'
                    }
                },
                {
                    id: 9,
                    subject: 'Schulbildung',
                    title: 'Informations- Technisches Berufskolleg',
                    company: 'Max-Eyth-Schule',
                    city: 'Kirchheim Teck',
                    description: 'Weiterbildung',
                    von: {
                        year: 2008,
                        month: 'September'
                    },
                    bis: {
                        year: 2009,
                        month: 'Juni',
                        fulldate: '2009-06-30'
                    }
                },
                {
                    id: 10,
                    subject: 'Schulbildung',
                    title: 'Wirtschatfsschule',
                    company: 'Jakob-Friedrich-Schöllkopf-Schule',
                    city: 'Kirchheim Teck',
                    description: 'Abschluss: Mittlere Reife - Wirtschaft',
                    von: {
                        year: 2006,
                        month: 'September'
                    },
                    bis: {
                        year: 2008,
                        month: 'Juli',
                        fulldate: '2008-07-31'
                    }
                },
                {
                    id: 11,
                    subject: 'Berufserfahrung',
                    title: 'Work and Travel',
                    company: 'Australien',
                    city: '',
                    description: 'Interkulurelles Bildungsjahr in Australien',
                    von: {
                        year: 2016,
                        month: 'September'
                    },
                    bis: {
                        year: 2017,
                        month: 'Juli',
                        fulldate: '2017-07-31'
                    }
                },
                {
                    id: 12,
                    subject: 'Ausbildung',
                    title: 'Ausbildung - Fachinformatiker für Anwendungs-Entwicklung',
                    company: 'Creative Software GmbH',
                    city: 'Weilheim Teck',
                    description: 'Abschluss: Fachinformatiker für Anwendungs-Entwicklung. Schwerpunkt: C#, MS-SQL',
                    von: {
                        year: 2012,
                        month: 'September'
                    },
                    bis: {
                        year: 2015,
                        month: 'Juli',
                        fulldate: '2015-07-31'
                    }
                },
                {
                    id: 7,
                    subject: 'Berufserfahrung',
                    title: 'Softwareentwicklung C#/.NET & Vue.js',
                    company: 'DIRS21 by TourOnline AG',
                    city: 'Wernau',
                    description: 'Frontend-Entwicklung mit Vue.js/JavaScript, Backend-Entwicklung mit C#/.NET, RESTful API-Entwicklung, Einsatz von Agile & Scrum.',
                    von: {
                        year: 2021,
                        month: 'Oktober'
                    },
                    bis: {
                        year: 2023,
                        month: 'März',
                        fulldate: '2023-03-01'
                    }
                },
            ]
        };
    }
}
</script>

<style>
body {
    background-color:#212A3D
}
</style>
