<template>
    <div class="intro">
        <div class="welcome">
            Willkommen auf meiner Webseite. Hier erfahren Sie mehr über mich und meinen beruflichen Werdegang.
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'LandingPage'
}
</script>

<style>

.welcome {
    padding: 5vw;
    font-size: 1em;
    text-align: center;
    padding-top: 10vh;
    color: black;
}
header {
    text-align: center;
}

header h1 {
    font-size: 3em;
}

header p {
    font-size: 1.2em;
    color: #666;
}

.space {
    padding-top: 5vh;
}


.intro {
    position: relative;
    min-height: 100vh;
}

.intro::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('/src/assets/erik.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(120%) contrast(90%) sepia(50%) blur(0px);
    /* Filter wird nur auf das Pseudo-Element angewendet */
    z-index: -1;
    /* Stellt sicher, dass der Hintergrund unter dem Inhalt liegt */
}

/* Media Query für mobile Ansichten */
@media screen and (max-width: 768px) {

    /* Dieser Wert kann je nachdem, wann Sie den Übergang zur mobilen Ansicht haben möchten, angepasst werden */
    .intro::before {
        background-position: 30% center;
        /* 50% (Standardmittelpunkt) + 15% = 65% */
    }
}</style>