<template>
    <div>
        <span>Under construction</span>
    </div>
</template>


<script>
export default {
    name: 'MainProjects',
    data() {
        return {
            
        }
    },
}
</script>