<!-- components/Navbar.vue -->

<template>
    <div class="navbar-container">
        <!-- Logo -->
        <!-- <div class="logo-container">
          <img alt="Vue logo" src="@/assets/logo.png" class="logo">
      </div> -->

        <!-- Navigation -->
        <nav>
            <ul>
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/lebenslauf">Lebenslauf</router-link></li>
                <li><router-link to="/projekte">Projekte</router-link></li>
            </ul>
        </nav>
    </div>
</template>
  
<script>
export default {
    name: 'NavBar'
}
</script>
  
<style scoped>
body {
    /* font-family: Arial, sans-serif; */
    margin: 0;
    padding: 0;
}

.navbar-container {
    /* text-align: center;
    position: absolute;
    width: 100%;
    z-index: 2;
    background-color: #69AAA3;
    height: 80px; */

        /* text-align: center; */
        position: relative;
    width: 100%;
    z-index: 2;
    background-color: #69AAA3;
    /* height: 80px;
}


.logo {
    width: 20vw;
    /* Sie können die Größe nach Bedarf anpassen */
}

nav {
    /* background-color: #333; */
    color: #FFFFFF;
    padding: 20px 0;
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

nav ul li {
    display: inline;
    margin: 0 20px;
}

nav ul li a {
    color: #2c2b2b;
    text-decoration: none;
    font-size: 3vh;
    /* Hier wurde die Schriftgröße festgelegt */
}

nav ul li a:hover {
    text-decoration: underline;
    /* Unterstreichung beim Überfahren mit der Maus */
}

.router-link-exact-active {
    font-weight: bold;
    /* Fettschrift für den aktiven Link */
}

/* Media Query für mobile Ansichten */
@media screen and (max-width: 768px) {
    nav ul li a {
        font-size: 2vh;
    }

    .navbar-container {
        height: 60px;
    }
}
</style>