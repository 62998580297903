<template>
  <div id="app">
    <NavBar /> <!-- Die Navbar wird immer angezeigt -->
    <router-view /> <!-- Hier wird der Inhalt der gerouteten Komponente gerendert -->
  </div>
</template>

<script>

import NavBar from '@/components/NavBar.vue';

export default {
  name: 'App',
  components: {
    NavBar // Registrieren Sie die Navbar-Komponente
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'Urbanist', sans-serif;
  /* Hier wurde die Schriftfamilie festgelegt */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}

/* Reset von Margins und Paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  --colorYears: #E3563D;
  --colorNavBar: #E3563D;
  --colorAusbildung: #414361;
  --colorStudium: #984036;
  --colorBerufserfahrung: #88309a;
  --colorAusbildung: #BE375F;

  box-sizing: border-box;

}

body {
    overflow-x: hidden;
}
</style>