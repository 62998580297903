<template>
    <div class="timeline">

        <div v-for="year in this.years" :key="'year-' + year" class="year-section">
            <h1>{{ year }}</h1>
            <div v-for="entry in this.entriesByYear[year]" :key="entry.id" class="timeline-item clearfix">
                <div class="timeline-content" :class="getBorderClass(entry)"
                >
                <!-- :style="{ 'border-color': getSubjectColor(entry) } "-->

                    <div class="subject">
                        <h4 :style="{ color: getSubjectColor(entry) }">
                            {{ entry.subject }}
                        </h4>
                        <div>
                            <h1 class="header">{{ entry.title }}</h1>
                            <p>{{ entry.company }}</p>
                            <p>{{ entry.city }}</p>
                            <p></p>
                            <br>
                            <p>{{ entry.description }}</p>
                        </div>
                        <br>

                        <!-- <div class="range">
                            <h4>{{ entry.von.month }} {{ entry.von.year }}</h4>
                            <h4>{{ entry.bis.month }} {{ entry.bis.year }}</h4>
                        </div> -->
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'TimeLine',
    props: {
        entries: Array
    },
    methods: {
        calculatePosition(date) {
            let yearIndex = this.years.indexOf(date.year);
            let monthOrder = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
            let monthPosition = (monthOrder.indexOf(date.month) / 11);
            let yearPosition = yearIndex / (this.years.length - 1);
            return `${yearPosition + monthPosition}%`;
        },
        getBorderClass(entry) {
            return entry.index % 2 === 0 ? 'left' : 'right';
        },
        getBorderStyle(entry) { 
            if (entry.index % 2 === 0) {
                return { 'border-left': `1px solid ${this.getSubjectColor(entry)}` };
            } else {
                return { 'border-right': `1px solid ${this.getSubjectColor()}` };
            }
        },
        getSubjectColor() {

        //   --colorYears: #E3563D;
        //   --colorNavBar: #E3563D;

        //   --colorAusbildung: #414361;
        //   --colorStudium: #984036;
        //   --colorBerufserfahrung: #F64668;
        //   --colorAusbildung: #FE9677;

            // switch (entry.subject) {
            //     case 'Berufserfahrung':
            //         return '#88309a';
            //     case 'Ausbildung':
            //         return '#BE375F';
            //     case 'Studium':
            //         return '#984036';
            //     case 'Schulbildung':
            //         return '#FE9677';
            //     default:
            //         return 'backgroundcolor';
            // }

            return '#984036';
        },
        organizeEntriesByYear() {
            let indexCounter = 1;  // Starten Sie den Index-Counter

            // Für jedes eindeutige Jahr
            this.years.forEach(year => {
                // Filtern Sie Einträge, die zu diesem Jahr gehören
                let entriesForThisYear = this.entries.filter(entry => entry.bis.year === year);

                // Weisen Sie jedem Eintrag in diesem Jahr einen Index zu
                entriesForThisYear = entriesForThisYear.map(entry => {
                    entry.index = indexCounter++;  // Weisen Sie den aktuellen Wert von indexCounter zu und erhöhen Sie ihn danach
                    return entry;
                });

                // Fügen Sie die gefilterten (und jetzt indizierten) Einträge dem Dictionary hinzu
                this.entriesByYear[year] = entriesForThisYear;
            });

            return this.entriesByYear;
        },
        uniqueYears() {
            this.entries.forEach(entry => {
                this.years.add(entry.von.year);
                this.years.add(entry.bis.year);
            });

            console.log(this.years);
            // Konvertiert das Set in ein sortiertes Array.
            this.years = [...this.years].sort((a, b) => b - a);
            console.log(this.years);
        }

    },
    created() {
        this.uniqueYears();
        this.organizeEntriesByYear();
    },
    data() {
        return {
            years: new Set(),
            entriesByYear: {},
            currentEntryIndex: 1 // Laufvariable
        }
    }

}
</script>

<style scoped>
.range {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.timeline-content.left {
    float: left;
    right: 25%;
    /* border-left: 1px solid; */
}

.timeline-content.left::before {
    right: -5px;
}

.timeline-content.right {
    float: right;
    left: 25%;
    /* border-right: 1px solid; */
}

.timeline-content.right::before {
    left: -5px;
}

.timeline {
    position: relative;
    max-width: 50vw;
    margin: 0 auto;
    padding: 40px 0;
    /* list-style-type: none;
    top: 15vw; */
}

.timeline-item {
    position: relative;
}

.timeline-date {
    width: 60px;
    left: 50%;
    text-align: center;
    position: absolute;
    margin-left: -30px;
    background: #2c3e50;
    color: white;
    border-radius: 50%;
    padding: 5px;
}

.timeline-content {
    /* background: #f4f4f4; */
    border-radius: 10px;
    padding: 9px;
    width: 70%;
    top: 10px;
    margin-bottom: 15px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    position: relative;
    text-align: left;
}

.header {
    color: #69AAA3;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.clearfix::before {
    content: '';
    position: absolute;
    top: 0px;
    /* Abstand von oben */
    bottom: 0;
    left: 50%;
    width: 2px;
    /* margin-left: -1px; */
    background: #69AAA3;
}

.year-section {
    padding-top: 10px;
    padding-bottom: 10px;
}

.year-section>h1 {
    color: #E3563D;
    font-size: 4em;
}

@media (max-width: 768px) {

    .timeline {
        padding: 0;
    }

    .timeline:before {
        left: 20px;
    }

    .timeline-content {
        width: calc(100% - 90px);
        /* Wir ziehen 70px ab, um Raum für den vertikalen Strich und etwas Abstand zu lassen. */
        float: none;
        /* margin-left: 40px; */
        /* Verschiebung des Inhalts nach rechts, um Raum für den Strich zu lassen. */
        /* margin-top: 30px; */
        /* Ein kleiner Abstand, damit der Inhalt unter dem Datum steht. */
        float: none !important;
        right: 0 !important;
        left: 15vw !important;
        top: 0;
        /* text-align: right !important; */
        padding-right: 0;
        padding-left: 0;
        padding-top:0;
    }

    .timeline-content.left,
    .timeline-content.right {
        border-left: none !important;
        border-right: none !important;
        /* border-bottom: 1px solid; */
    }

    .timeline-date {
        left: 60px;
        position: absolute;
        top: -25px;
    }

    .timeline {
        top: 0;
        max-width: none;
    }

    .year-section {
        min-height: 50px;
        border-top: 5px solid gray;

    }

    .year-section>h1 {
        left: 1vw;
        /* margin-top: 35px;         */
        position: absolute;
        font-size: x-large;
    }

    .clearfix::before {
        /* content: none; */
        left: 12vw;
        margin-left: 0;
        /* bottom: -50px; */
        top: -10px;
    }

    .clearfix {
        margin-left: 5vw;
    }
}
</style>

